import { Avatar, Box, Icon } from "@chakra-ui/react";
import { BsWhatsapp } from "react-icons/bs";

const WhatsappDrawer = () => {
  const linkWa = `https://wa.me/6287711217161?text=${encodeURIComponent('Hallo,saya ingin bertanya mengenai kelas di mistercoding')}`;
  return (
    <Box pos={"fixed"} bottom={{ sm: 10, base: 24 }} right={{ sm: 10, base: 4 }} zIndex={1000}>
      <Avatar
        as={"a"}
        href={linkWa}
        target={"_blank"}
        bg="green.500" border={"3px solid white"}
        icon={<BsWhatsapp fontSize="1.9rem" />}
      />
    </Box>
  );
};

export default WhatsappDrawer;
