import { Box, BoxProps } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface Props extends BoxProps {
  children: ReactNode;
}

const Container: FC<Props> = ({ children, ...props }) => {
  return (
    <Box
      {...props}
      px={{ sm: 100, base: 4 }}
      maxW={{ sm: "8xl", base: "6xl" }}
      mx={"auto"}
    >
      {children}
    </Box>
  );
};

export default Container;
