import { darkmodeState } from "@/state/darkmode.state";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { useCookies } from 'react-cookie';

const useDarkmode = () => {
    const darkmode = useRecoilValue(darkmodeState);
    const setDarkMode = useSetRecoilState(darkmodeState);
    const [cookies, setCookie] = useCookies(['darkmode']);

    const handleSetDarkMode = (d: boolean) => {
        setCookie("darkmode", d);
    }

    // useEffect(() => {
    //     const time = new Date().getHours();
    //     if (![false,true].includes(cookies.darkmode)) {
    //         console.log("abc", "dark cookie", cookies.darkmode);
    //         if (time > 17 || time < 6) {
    //             setCookie("darkmode", true)
    //         } else {
    //             setCookie("darkmode", false)
    //         }
    //     }
    // }, []);

    useEffect(() => {
        if (cookies.darkmode == true) {
            setDarkMode(true)
        } else {
            setDarkMode(false);
        }

    }, [cookies])
    return { darkmode, setDarkMode: handleSetDarkMode }
};

export default useDarkmode;
