// export const mister_coding_logo = "https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/mistercoding_new_60c7279a36.png";

//https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/mistercoding_white_5c7ae6c2c3.png
export const cdn1 = (path: string="") => {
    return path ? 'https://cdn1.mistercoding.com/' + path : ''
}

export const cdn2 = (path: string="") => {
    return path?'https://cdn2.mistercoding.com/' + path:''
}

export const mister_coding_logo = cdn2("mistercoding_white_5c7ae6c2c3.png");
// export const mister_coding_logo2 = cdn2("mistercoding_new_60c7279a36.png");
// export const mister_coding_logo2 = "https://mrcodingblog.s3.ap-southeast-1.wasabisys.com/logo_mister_coding3_346be29600.png"
export const mister_coding_logo2 = "https://img.picopixs.com/mrcodingimg/Images/mr_coding_logo_text.png"

export const mister_coding_logo_icon = cdn2('mister_without_text_6dbb22bb78.png')

