import { ReactNode } from "react";

import {
  Box,
  Container,
  Image,
  SimpleGrid,
  HStack,
  Stack,
  Text,
  useColorModeValue,
  Icon,
  Flex,
  Divider,
} from "@chakra-ui/react";
import Link from "next/link";
import { mister_coding_logo, mister_coding_logo2 } from "@/utils/image";

import {
  LinkedinIcon,
} from "react-share";
import { FaTiktok } from "react-icons/fa";
import { BsInstagram, BsYoutube } from "react-icons/bs";
import useDarkmode from "@/hooks/userDarkmode";

const Logo = (props: any) => {
  const { darkmode } = useDarkmode();
  return (
    <HStack>
      <Image alt="Mister Coding" width={180} src={darkmode == true ? mister_coding_logo : mister_coding_logo2} />
    </HStack>
  );
};

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function Footer() {
  const { darkmode } = useDarkmode();
  return (
    <Box
      mt={10}

      color={darkmode == true ? "gray.200" : useColorModeValue("gray.700", "gray.200")}
    >
      <Divider borderColor={"gray.400"}/>
      <Container as={Stack} px={{ sm: 100, base: 4 }}
        maxW={{ sm: "8xl", base: "6xl" }}
        mx={"auto"} py={{sm:28,base:20}}>
        <SimpleGrid
          templateColumns={{
            sm: "1fr 1fr",
            md: "2fr 1fr 1fr 1fr 1fr",
            base: "1fr",
          }}
          spacing={8}
        >
          <Stack spacing={6}>
            <Box>
              <Logo color={useColorModeValue("gray.700", "white")} />
            </Box>

            <Text fontSize={"sm"}>
              Tempat belajar koding indonesia
            </Text>

            <Stack>
              <Text fontSize={"sm"}>
                © 2023 PT Koding Digital Indonesia. All rights reserved
              </Text>
              {/* <Text fontSize={"sm"}>
              Bekasi, Jawa Barat
            </Text>
            <Text fontSize={"sm"}>
              WA (082246835100)
            </Text> */}
              <Text fontSize={"sm"}>
                Email: <Text as={"a"} color={"blue.400"} href="mailto:support@mistercoding.com">support@mistercoding.com</Text>
              </Text>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>
              <b>Company</b>
            </ListHeader>
            <Link href={"/privacy-policy"}>Privacy Policy</Link>
            {/* <Link href={"/privacy-policy"}>Certificate</Link> */}
            <Link href={"/terms-and-condition"}>Terms & Condition</Link>
            {/* <Link href={"/jadimentor"}>Gabung Jadi mentor</Link> */}
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>
              <b>Useful Links</b>
            </ListHeader>
            <Link href={"/verify-certificate"}>Cek Sertifikat</Link>
            {/* <Link href={"/terms-and-condition"}>Terms & Condition</Link> */}
            <Link href={"/jadimentor"}>Gabung Mentor</Link>
            <Link href={"/learning-method"}>Metode Belajar</Link>
            <Link href={"/roadmap"}>Roadmap</Link>
            <Link href={"https://sourcelibs.com/"}>Free & Pemium Resources</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader><b>Posts</b></ListHeader>
            <Link href={"/post"}>Article</Link>
            <Link href={"/sharing"}>Share Posts</Link>
            <Link href={"/post-set"}>Tutorial Set</Link>
          </Stack>
          {/* <Stack align={"flex-start"}>
            <ListHeader>Support</ListHeader>
            <Link href={"#"}>Help Center</Link>
            <Link href={"#"}>Terms of Service</Link>
            <Link href={"#"}>Legal</Link>
            <Link href={"#"}>Privacy Policy</Link>
            <Link href={"#"}>Status</Link>
          </Stack> */}
          <Stack align={"flex-start"}>
            <ListHeader>
              <b>Follow Us</b>
            </ListHeader>
            <Box mt={4}>
              <Flex alignItems={"center"}>
                <Box>
                  <Link href={"https://www.linkedin.com/company/mister-coding"}>
                    <LinkedinIcon size={30} className="rounded-lg ml-1" />
                  </Link>
                </Box>
                <Box ml={5} mt={2}>
                  <Link href={"https://www.tiktok.com/@mistercoding.com"}>
                    <Icon fontSize={"2xl"} as={FaTiktok} />
                  </Link>
                </Box>
                <Box ml={5} mt={2}>
                  <Link href={"https://www.youtube.com/@mister-coding"}>
                    <Icon fontSize={"4xl"} as={BsYoutube} color="red.500" />
                  </Link>
                </Box>
                <Box ml={5} mt={2}>
                  <Link href={"https://www.instagram.com/mistercodings/"}>
                    <Icon fontSize={"3xl"} as={BsInstagram} color="pink.500" />
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
