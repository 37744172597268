import { Box, Button, Flex, Text } from "@chakra-ui/react";
import Container from "../Container";
import { useLazyGetJsonPromoBannerQuery } from "@/store/api/json.api";
import Link from "next/link";
import { useEffect } from "react";

export default function PromoBootcamp() {

    const [getBanner, { data }] = useLazyGetJsonPromoBannerQuery();

    useEffect(() => {
        getBanner("banner_promo")
    }, [])
    return (
        <>
            {
                data?.is_active == true ? <>
                    <Box py={4} bg={"gray.700"}>
                        <Container>
                            <Flex justifyContent={"space-between"} flexWrap={"wrap"}>
                                <Flex>
                                    <Box>
                                        <Text color={"gray.50"} fontWeight={"bold"} fontSize={"lg"}>{data.title}</Text>
                                        <Text color={"yellow.300"} fontSize={"lg"}>
                                            <Text fontWeight={"bold"} as={"span"}>{data.subtitle}</Text> <Text as={"span"} color={"red.300"}><del>IDR. {new Intl.NumberFormat("ID").format(data.price_original)}</del></Text> {" "} <Text as={"span"} color={"gray.100"} fontWeight={"bold"}>IDR. {new Intl.NumberFormat("ID").format(data.price_discount)}</Text>
                                        </Text>
                                    </Box>
                                </Flex>
                                <Link href={data.link}>
                                    <Button my={{sm:0,base:4}} rounded={"xl"} bg={"gray.900"} _hover={{ bg: "gray.800" }} color={"gray.100"} py={6}>Gabung Kelas</Button>
                                </Link>
                            </Flex>
                        </Container>
                    </Box>
                </> : <></>
            }
        </>
    )
}