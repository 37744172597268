import { useAppState } from "store/hookstate/app.state";
import { Box, Spinner } from "@chakra-ui/react";

const Loading = () => {
  const appState = useAppState();
  return (
    <>
      {appState.getLoading() == true && (
        <Box
          className="bg-gray-700_bg-opacity-10"
          height={"100vh"}
          width={"100vw"}
          pos={"fixed"}
          zIndex={10000}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner size={"xl"} color={"red.500"}/>
        </Box>
      )}
    </>
  );
};

export default Loading;
