import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Text,
} from "@chakra-ui/react";
import algoliasearch from "algoliasearch/lite";
import {
  InstantSearch,
  Configure,
  Hits,
  SearchBox,
  Panel,
  RefinementList,
  Pagination,
  Highlight,
} from "react-instantsearch-dom";
import { Search } from "model/search";
import { useState } from "react";
import Link from "next/link";

const searchClient = algoliasearch(
  "U17DO05B04",
  "1bb2bf4635e0cc3ea48c8a57a0d8168d"
);
const index = searchClient.initIndex("article_class");

const SearchArticleKelas = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [search, setSearch] = useState<Search[]>([]);
  const [txt, setTxt] = useState<string>();

  const handleSearch = (val: any) => {
    index.search(val).then(({ hits }: any) => {
      setTxt(val);
      const data: Search[] = hits;
      setSearch(data);
    });
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={"2xl"}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader color={"gray.600"}>Cari Artikel, Kelas</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={10}>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                placeholder="Cari sesuatu..."
                size="lg"
                rounded={"full"}
                onChange={(e) => {
                  handleSearch(e.target.value);
                }}
              />
            </InputGroup>

            <Box maxH={500} overflowY={"scroll"} my={8}>
              <>
                {txt && (
                  <>
                    {search.map((item, i) => {
                      return (
                        <Box key={i} p={4} my={4} bg={"gray.100"}>

                          {
                            item.content_type == "post" && <Text
                              as={Link}
                              color={"gray.700"} _hover={{ color: "blue.300" }}
                              href={`/post/${item.slug}`}
                              onClick={() => {
                                onClose();
                              }}
                            >
                              Article -
                              {item.title}
                            </Text>
                          }
                          <>
                            {
                              item.content_type == "sharing" && <Text
                                as={Link}
                                color={"gray.700"} _hover={{ color: "blue.300" }}
                                href={`/sharing/${item.slug}`}
                                onClick={() => {
                                  onClose();
                                }}
                              >
                                Sharing -
                                {item.title}
                              </Text>
                            }
                          </>

                        </Box>
                      );
                    })}
                  </>
                )}
              </>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>

      <InputGroup w={{sm:400}}>
        <InputLeftElement pointerEvents="none">
          <SearchIcon color="gray.300" />
        </InputLeftElement>
        <Input
          placeholder="Search article, kelas"
          size="md"
          rounded={"full"}
          onClick={onOpen}
        />
      </InputGroup>
    </>
  );
};

export default SearchArticleKelas;
