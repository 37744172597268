import Footer from "@/components/Footer";
import Loading from "@/components/Loading";
import Navbar from "@/components/Navbar";
// import Promo from "@/components/Promo";
import WhatsappDrawer from "@/components/WhatsappDrawer";
import { app } from "@/config/app";
import { Box } from "@chakra-ui/react";
import { Suspense, useEffect, useState } from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import useDarkmode from "@/hooks/userDarkmode";
import useRefCode from "@/hooks/useRefCode";
import PromoBootcamp from "@/components/promo/PromoBootcamp";

const Promo = dynamic(() => import("@/components/Promo"), {
  ssr: false,
});


export default function Layout({ children }: any) {
  const [promoShow, setPromoShow] = useState(false);
  const router = useRouter();
  const { darkmode } = useDarkmode();
  const [stg, setStg] = useState(false)
  const {cookies, setCookie} = useRefCode()

  useEffect(() => {
    if (router) {

      if (router.pathname == "/" || router.pathname == "/kelas/[slug]") {
        setPromoShow(true);
      }

      const q: any = router.query;
      if (q.ref) {
        setCookie("ref_code",q.ref)
      }
    }

  }, [router]);

  useEffect(() => {
    if (darkmode == true) {
      localStorage.setItem("darkmode", "yes")
      setStg(true)
    } else {
      localStorage.setItem("darkmode", "no")
      setStg(false)
    }
  }, [darkmode])

  return (
    <Box bg={darkmode == true ? "gray.900" : "gray.50"} minH={"100vh"} pb={{ sm: 0, base: 40 }}>
      {/* {promoShow == true && <Promo />} */}
      {promoShow == true && <PromoBootcamp />}
      <Navbar />
      <Box>
        <Suspense fallback={<Loading />}>{children}</Suspense>
      </Box>
      <WhatsappDrawer />
      <Footer />
    </Box>
  );
}

